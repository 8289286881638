a,
.btn-link {
  color: var(--electron-blue);
}

.btn-link {
  border: 0;
  background: none;
}

a.loading,
button.loading,
.btn.loading {
  pointer-events: none;
  cursor: default;
}

a[disabled],
button[disabled],
.btn[disabled] {
  pointer-events: none;
}

.btn-primary {
  background-color: var(--purple);
  border-color: var(--purple);
  color: white !important;
}

.btn-primary:hover {
  background-color: rgba(0, 1, 0, );
  border-color: var(--purple);
}

.btn-primary:disabled {
  background-color: rgba(0, 1, 0, );
  border-color: rgba(0, 1, 0, );
  cursor: progress;
}

.btn-emphasis-primary {
  background-color: var(--georgia-peach);
  border-color: var(--georgia-peach);
  font-family: "Roboto", Sans-serif;
  color: white;
  font-weight: 600;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, .1);
}

.btn-emphasis-primary:hover {
  background-color: rgba(0, 1, 0, );
  border-color: var(--georgia-peach);
  color: white;
}

.btn-emphasis-primary:disabled {
  background-color: var(--green-grey);
  border-color: var(--green-grey);
  cursor: progress;
}

.btn-outline-primary {
  color: var(--purple);
  border-color: var(--purple);
}

.btn-outline-primary:hover {
  background-color: var(--purple);
  border-color: var(--purple);
}

.btn-white {
  color: #2c3a47;
  background-color: white;
  border-color: #d2d2d2;
}

.btn-white:hover {
  background-color: #f8f9fa;
  border-color: #2c3a47;
}