.turbine-assistant {
  background-color: #f3f3f3 !important;
  background-image: url(https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb.png) !important;
  background-repeat: repeat !important;
  background-size: 40% !important;
}

.turbine-assistant p {
  font-size: 1rem;
}

.turbine-assistant .card-header {
  border-radius: unset;
}

.tw-assistant-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: fixed;
  bottom: 0;
  padding: .5rem;
  background: white;
  width: 100%;
  left: 0;
  border-top: 1px solid #dee2e6;
  z-index: 100;
  margin-top: .5rem;
  font-size: 12px;
  color: #343a40;
}

.tw-assistant-footer a {
  color: black !important;
}

@media (min-width: 497.98px) {
  .tw-assistant-footer {
    font-size: 13px;
  }
}

@media (min-width: 767.98px) {
  .turbine-assistant .card-header {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
  }

  /* .turbine-assistant p {
    font-size: .75rem;
  } */
}