* {
  box-sizing: border-box;
}

body {
  background-color: #f8f9fa !important;
  color: #3f5366;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

body:not([class*="route-"]) {
  background-image: url("https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png");
  background-repeat: repeat;
  background-color: black;
}

.route-page .container-fluid {
  max-width: 600px;
  margin: auto;
}

p {
  font-size: 1.125rem;
}

small p {
  font-size: 1rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.fa {
  font-size: 22px;
  transform: translateY(50deg);
}

.feather {
  height: 16px;
  vertical-align: text-bottom;
  width: 16px;
}

[role="main"] {
  padding-top: 18px;
}

.opacity-0 {
  opacity: 0;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.text-muted a {
  color: #1b9cfc;
}

.text-light {
  color: #ccc !important;
}

.opacity-50 {
  opacity: 50%;
}

.text-break-all {
  word-break: break-all;
}

.react-code-input input {
  padding: 0;
}

.show-when-printing {
  display: none;
}

.overflow-content {
  overflow-x: scroll;
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.pointer-events-none {
  pointer-events: none;
}

.rounded-tl {
  border-top-left-radius: .25rem .25rem;
}

.rounded-tr {
  border-top-right-radius: .25rem .25rem;
}

@media (max-width: 767.97px) {
  .react-code-input input {
    width: 31px !important;
    font-size: 22px !important;
  }
}

@media (min-width: 767.98px) {
  .react-code-input input {
    width: 36px !important;
    font-size: 32px !important;
  }
}

@media print {
  [role="main"] {
    background-color: red !important;
    max-width: initial !important;
  }

  .page-break-after {
    page-break-after: always;
  }

  .page-break-before {
    page-break-before: always;
  }

  .print-p-2 {
    padding: 0.5rem !important;
  }

  .hide-when-printing {
    display: none;
  }

  .show-when-printing {
    display: block;
  }
}