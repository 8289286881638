/* BRANDING
 * https://flatuicolors.com/palette/in
 */

:root {
  --navbar-height: 64px;
  /* Colors */
  --ships-officer: #2c3a47;
  --keppel: #56b19f;
  /* --clear-chill: #1b9cfc; */
  --fiery-fuchsia: #b33771;
  --honey-glow: #eab543;
  --falling-star: #cad3c8;
  --georgia-peach: #fd7272;
  --sarawak-white-pepper: #f8efba;
  --sarawak-white-pepper-hover: #fbec95;
  --electron-blue: #0984e3;
  --purple: #8e44ad;
  --white: #fff;
  --black: #000;
  --green-grey: #cad3c8;
  --grey: #777;
  --grey-light: #fdfdfd;
  --gray-dark: #999;
  --gray-darker: #333;
  --blue: #007bff;
  --yellow: #fbc547;
}

.text-keppel {
  color: var(--keppel);
}

.text-black {
  color: black;
}

.text-ships-officer {
  color: var(--ships-officer) !important;
}

.badge-success {
  background-color: var(--keppel);
}

.bg-danger-light {
  background-color: rgba(179, 55, 113, .2) !important;
}