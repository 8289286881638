.manage-apprentice-agreement .OrgConsoleHeader .card-header {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.manage-apprentice-agreement p {
  font-size: 1rem;
}

@media print {
  .manage-apprentice-agreement {
    background-color: white !important;
    background-image: none !important;
  }

  .form-control:disabled {
    background-color: white !important;
  }
}

.manage-apprentice-agreement .border:not(.border-danger),
.manage-apprentice-agreement .border-bottom,
.manage-apprentice-agreement .border-top,
.manage-apprentice-agreement .border-left,
.manage-apprentice-agreement .border-right,
.manage-apprentice-agreement .table-bordered thead td,
.manage-apprentice-agreement .table-bordered thead th,
.manage-apprentice-agreement .table-bordered td,
.manage-apprentice-agreement .table-bordered th {
  border-color: black !important;
}

.manage-apprentice-agreement .table-bordered thead td,
.manage-apprentice-agreement .table-bordered thead th {
  padding: 2px 0 2px 10px;
  border-bottom-width: 1px !important;
}

/**
  ORG REFERRAL
*/

.orgReferral-container label {
  width: 205px;
}

.orgReferral-container label:nth-child(1),
.orgReferral-container label:nth-child(6) {
  width: 115px !important;
}

/**
  EDUCATION LEVEL
*/
.educationLevel-container label {
  width: 130px;
}

.educationLevel-container label:nth-child(4),
.educationLevel-container label:nth-child(5),
.educationLevel-container label:nth-child(6) {
  width: 115px !important;
}

.educationLevel-container label:nth-child(4) {
  align-items: start !important;
}

.educationLevel-container label:nth-child(4) span {
  white-space: pre-wrap !important;
}

/**
  APPRENTICE RESPONSIBILITY
*/

.ApprenticeResponsibility ol {
  margin-left: 3rem !important;
}

.ApprenticeResponsibility li {
  /* font-size: 1.25rem !important; */
  list-style: decimal !important;
  margin-bottom: 1rem !important;
}

/**
  APPRENTICE AGREEMENT : GENERAL
*/

.manage-apprentice-agreement .container-fluid .row {
  width: 1036px;
}

.ApprenticeAgreement-PageFooter {
  width: 950px;
}

.manage-apprentice-agreement .form-field-title {
  font-size: .7rem;
}

.font-size-125 {
  font-size: 1.25rem;
}

.font-size-15 {
  font-size: 1.5rem;
}

.font-size-1 {
  font-size: 1rem;
}

.sponsor-section strong {
  color: black;
}

.td-1 {
  width: 10%;
}

.td-2 {
  width: 20%;
}

.td-3 {
  width: 30%;
}

.td-4 {
  width: 40%;
}

.td-5 {
  width: 50%;
}

.td-6 {
  width: 60%;
}

.td-7 {
  width: 70%;
}

.td-8 {
  width: 80%;
}

.td-9 {
  width: 90%;
}


.td-15 {
  width: 15%;
}

.td-25 {
  width: 25%;
}

.td-35 {
  width: 35%;
}

.td-45 {
  width: 45%;
}

.td-55 {
  width: 55%;
}

.td-65 {
  width: 65%;
}

.td-75 {
  width: 75%;
}

.td-85 {
  width: 85%;
}

.td-95 {
  width: 95%;
}