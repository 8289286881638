.EditOccupationElement-container .custom-control-label::before, .EditOccupationElement-container .custom-control-label::after {
  top: 0.8rem;
  width: 1.25rem;
  height: 1.25rem;
}

.backToTurbine {
  color: black;
  border: 1px solid white;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}

@media (hover: hover) {
  .backToTurbine:hover {
    background-color: #fbfafa;
    border-color: #d8d8d8;
    color: black;
    text-decoration: none !important;
 }
}

.pathways-header {
  background-image: url("https://s3.us-west-2.amazonaws.com/turbineworkforce.com/winding-road.jpg");
  background-position: -60px -10px;
  background-repeat: no-repeat;
  border-radius: 0.375rem 0.375rem 0 0;
}

@media (min-width: 768px) {
  .pathways-header {
    background-position: 0 0;
 }
}

.pathways-step-number {
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  background-color: #999;
  border-radius: 100%;
}
