.navbar-container {
  display: flex;
  justify-content: space-between;
}

.navbar-brand {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  text-transform: capitalize;
}

.nav-icon {
  height: 38px;
  margin-right: 8px;
}

.navbar {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) !important;
  z-index: 10;
}

.navbar .nav-link {
  cursor: pointer;
}

.navbar .top-nav-trigger {
  padding: 0.75rem 1rem;
}

.navbar .form-control {
  border-radius: 0;
  border-width: 0;
  padding: 0.75rem 1rem;
}

.navbar .dropdown-menu {
  cursor: pointer;
  position: absolute;
}

.navbar .dropdown-menu.right {
  left: auto;
  right: 1px;
}

.user-profile img {
  border-radius: 50%;
  margin-right: 10px;
  height: 38px;
  width: 38px;
}
