body.modal-active {
  overflow: hidden;
}

.turbine-modal {
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 15;
  background: rgba(0, 0, 0, .6);
  overflow-y: auto;
  will-change: contents;
  transition: opacity 150ms ease-in-out;
}

.turbine-modal .modal-container {
  margin: auto;
  will-change: contents;
  max-width: 40rem;
  min-height: fit-content;
}

.turbine-modal .modal-container .modal-content {
  position: relative;
  margin: 1rem;
  padding: 2rem;
  background-color: white;
  width: auto;
}

.turbine-modal .modal-container .title {
  font-size: 1.5rem;
  margin-top: 0;
}

.turbine-modal.large .modal-container {
  max-width: 60rem;
}

.turbine-modal.fullscreen .modal-container {
  max-width: none;
}

.turbine-modal.fullscreen .modal-content {
  margin: 0;
  height: 100vh;
  border-radius: 0;
}

.turbine-modal.dialog {
  text-align: center;
  z-index: 20;
}

.turbine-modal.dialog .modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 32rem;
  height: 100vh;
}

.turbine-modal.dialog .title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 500;
}

.turbine-modal.dialog .body {
  margin-bottom: 2rem;
  font-size: 0.875rem;
}

.turbine-modal.dialog .control .btn {
  display: inline-flex;
  width: auto;
}

.turbine-modal.dialog .control .btn.btn-link {
  color: #1b9cfc;
}

.turbine-modal.dialog .control .btn.btn-link:hover {
  background: none;
  text-decoration: underline;
}

.turbine-modal.dialog .control .btn + .btn {
  margin-left: 0.75rem;
}

.turbine-modal.middle .modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.turbine-modal .close {
  color: #4a4a4a;
  font-weight: normal !important;
  height: 40px;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 40px;
  z-index: 1;
}

.turbine-modal.flush .modal-content {
  padding: 0;
}

.turbine-modal-enter {
  opacity: 0;
}

.turbine-modal-enter .modal-content {
  transform: translateY(10%) scale(0.99);
}

.turbine-modal-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.turbine-modal-enter-active .modal-content {
  transform: translateY(0) scale(1);
  transition: transform 300ms ease-in-out;
}

.turbine-modal-enter-done {
  opacity: 1;
}

.turbine-modal-exit {
  opacity: 1;
}

.turbine-modal-exit .modal-content {
  transform: translateY(0) scale(1);
}

.turbine-modal-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.turbine-modal-exit-active .modal-content {
  transform: translateY(10%) scale(0.99);
  transition: transform 300ms ease-in-out;
}
