.form-control {
  box-sizing: border-box;
  font-size: 16px;
  height: auto;
  padding: 10px;
  position: relative;
}

.form-control:focus {
  z-index: 2;
}

.list-group-item:disabled, .list-group-item[disabled] {
  opacity: 0.5;
}
