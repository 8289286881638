.authpage,
.authpage body {
  height: 100%;
}

.authpage .container-fluid {
  align-items: center;
  height: 100%;
  justify-content: center;
}

@media (min-width: 768px) {
  .authpage .container-fluid {
    display: flex;
 }
}

.authpage body {
  align-items: center;
  text-align: center;
  background-color: black;
  background-image: url('https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png');
  background-repeat: repeat;
}

.authpage.login body {
  display: flex;
}

.authpage #root {
  width: 100%;
  height: 100%;
}

.authpage button[type="submit"] {
  min-width: 200px;
}

.authpage .card {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, .2);
}

.authpage .card-body p {
  font-size: 1rem;
}

.authpage-logged-in .container-fluid {
  height: calc(100% - 63px) !important;
}

.auth-container {
  max-width: 21rem;
  width: 100%;
}

.auth-container.expert {
  max-width: 830px;
}

