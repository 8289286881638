.employer-manage-employer-agreement .OrgConsoleHeader .card-header {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.employer-manage-employer-agreement p {
  font-size: 1rem;
}

@media print {
  .employer-manage-employer-agreement {
    background-color: white !important;
    background-image: none !important;
  }

  .form-control:disabled {
    background-color: white !important;
  }
}